import { createSelector } from 'reselect';

import { StoreState } from '../index';

import BusinessLocation from '@/types/businesslocation';
import Suspension from '@/types/suspension';
import WorkArea from '@/types/workarea';
import { OpTemplatesState } from '../op_templates/types';
import { UserState } from './types';

import _ from 'lodash';

// Types
import { BusinessValidationStatus } from '@/types/business';

const getSelectedUser = ( { user }: StoreState ) => {
    return user;
};

const getUserId = ( { user }: StoreState ) => {
    return user.id;
};

export const getBusinessId = ( { user }: StoreState ) => {
    return user.selectedBusiness
        ? user.selectedBusiness.id
        : 0;
};

const getBusinessSquareLogo = ( { user }: StoreState ) => {
    return user.selectedBusiness.squareLogo
        ? user.selectedBusiness.squareLogo
        : '';
};

const getBusinessUserId = ( { user }: StoreState ) => {
    return user.selectedBusiness
        ? (
            user.selectedBusiness.businessUserId
                ? user.selectedBusiness.businessUserId
                : user.selectedBusiness.businessuserId
        )
        : 0;
};

const getIsBusinessBonusPayOn = ( { user }: StoreState ) => {
    return user.selectedBusiness && user.selectedBusiness.isBonusPayOn
        ? user.selectedBusiness.isBonusPayOn
        : false;
};

const getBusinessContactId = ( { user }: StoreState ) => {
    return user.selectedBusiness && user.selectedBusiness.selectedLocation
        ? user.selectedBusiness.selectedLocation.id
        : 0;
};

const getBusinessDistrict = ( { user }: StoreState ) => {
    return user && user.selectedBusiness
        ? user.selectedBusiness.district
        : '';
};

const getBusinessDistrictId = ( { user }: StoreState ) => {
    return user && user.selectedBusiness
        ? user.selectedBusiness.districtId
        : 0;
};

const getUserBusinesses = ( { user }: StoreState ) => {
    return user.businesses;
};

const getSelectedBusiness = ( { user }: StoreState ) => {
    return user.selectedBusiness;
};

const getSelectedBusinessName = ( { user }: StoreState ) => {
    return user.selectedBusiness
        ? user.selectedBusiness.businessName
        : '';
};

const getSelectedBusinessBiography = ( { user }: StoreState ) => {
    return user.selectedBusiness
        ? user.selectedBusiness.biography
        : '';
};

const getSelectedBusinessUrls = ( { user }: StoreState ) => {
    return user.selectedBusiness
        ? user.selectedBusiness.urls
        : '';
};

const getIsInternalUser = ( { user }: StoreState ) => {
    return user.isInternalUser;
};

const getInternalDistrict = ( { user }: StoreState ) => {
    return user.internalDistrict;
};

const getIsLoggedIn = ( { user }: StoreState ) => {
    return user.loggedIn;
};

const getLogoutPending = ( { user }: StoreState ) => {
    return user.logoutPending;
};

const getUserSessionError = ( { user }: StoreState ) => {
    return user.hasUserSessionError;
};

const getUserEmail = ( { user }: StoreState ) => {
    return user.email;
};

const getUserFirstName = ( { user }: StoreState ) => {
    return user.firstName;
};

const getUserLastName = ( { user }: StoreState ) => {
    return user.lastName;
};

const getshowMultiBusinessUserWarning = ( { user }: StoreState ) => {
    return user.showMultiBusinessUserWarning;
};

const getLocations = ( { user }: StoreState ) => {
    return user && user.selectedBusiness
        ? user.selectedBusiness.locations
        : [];
};

const getBusinessPaymentType = ( { user }: StoreState ) => {
    return user && user.selectedBusiness && user.selectedBusiness.businessPaymentType
        ? user.selectedBusiness.businessPaymentType
        : '';
};

const getIsMarketplaceOn = ( { user }: StoreState ) => {
    return user && user.selectedBusiness
        ? !!user.selectedBusiness.isMarketplaceOn
        : user
            ? !!user.isMarketplaceOn
            : false;
};

const getAccessLevel = ( { user }: StoreState ): number => {
    return user && user.selectedBusiness && user.selectedBusiness.level
        ? user.selectedBusiness.level
        : 0;
};

const getUserTimeZoneId = ( { user }: StoreState ): string => {
    return user && user.selectedBusiness && user.selectedBusiness.timeZoneId
        ? user.selectedBusiness.timeZoneId
        : 'America/Chicago';
};

const getSelectedLocation = ( { user }: StoreState ): BusinessLocation => {
    return user && user.selectedBusiness && user.selectedBusiness.selectedLocation
        ? user.selectedBusiness.selectedLocation
        : {
            id: 0
            , name: ''
            , addressLine1: ''
            , addressLine2: ''
            , city: ''
            , state: ''
            , zip: ''
            , latlng: {
                coordinates: [ null, null ]
            }
        };
};

const getWorkAreas = ( { user }: StoreState ): WorkArea[] => {
    return user && user.selectedBusiness && user.selectedBusiness.workAreas
        ? user.selectedBusiness.workAreas
        : [];
};

const getIsAccounting = ( { user }: StoreState ): boolean => {
    return user && user.selectedBusiness && user.selectedBusiness.isAccounting
        ? user.selectedBusiness.isAccounting
        : false;
};

const getIsSessionCheckPending = ( { user }: StoreState ): boolean => {
    return user
        ? user.sessionCheckPending
        : false;
};

const getPostedOpsCountAllTime = ( { user }: StoreState ): number => {
    return user && user.selectedBusiness && user.selectedBusiness.postedOpsCountAllTime
        ? user.selectedBusiness.postedOpsCountAllTime
        : 0;
};

const getCreatedAt = ( { user }: StoreState ): string => {
    return user && user.selectedBusiness && user.selectedBusiness.createdAt
        ? user.selectedBusiness.createdAt
        : '';
};

const getOpTemplates = ( { opTemplates }: StoreState ): OpTemplatesState => {
    return opTemplates && opTemplates.templates
        ? opTemplates.templates
        : false;
};

const getSuspensions = ( { user }: StoreState ): Suspension[] => {
    return user.suspensions || [];
};

const getUserRole = ( { user }: StoreState ): string => {
    return user.roleName || '';
};

const getBusinessW2 = ( { user }: StoreState ): boolean => {
    return user.selectedBusiness?.isW2 ?? false;
};

const getBusinessBillingState = ( { user }: StoreState ): string => {
    return user.selectedBusiness?.billingState ?? '';
};

const getBusinesssTiered = ( { user }: StoreState ): boolean => {
    return user.selectedBusiness?.isTiered ?? false;
};

const getBusinessValidationStatus = ( { user }: StoreState ): BusinessValidationStatus => {
    return user.selectedBusiness.internalValidationStatus ?? 'pending';
};

export const selectIsBusinessTiered = createSelector(
    getBusinesssTiered
    , isTiered => isTiered
);

export const selectBusinessBillingState = createSelector(
    getBusinessBillingState
    , billingState => billingState
);

export const selectSelectedUser = createSelector(
    getSelectedUser
    , selectedUser => selectedUser
);

export const selectInternalDistrict = createSelector(
    getInternalDistrict
    , internalDistrict => internalDistrict
);

export const selectUserBusinesses = createSelector(
    getUserBusinesses
    , businesses => businesses
);

export const selectUserId = createSelector(
    getUserId
    , userId => userId
);

export const selectBusinessId = createSelector(
    getBusinessId
    , businessId => businessId
);

export const selectBusinessSquareLogo = createSelector(
    getBusinessSquareLogo
    , ( businessSquareLogo: string ) => businessSquareLogo
);

export const selectBusinessUserId = createSelector(
    getBusinessUserId
    , businessUserId => businessUserId
);

export const selectIsBusinessBonusPayOn = createSelector(
    getIsBusinessBonusPayOn
    , isBonusPayOn => isBonusPayOn
);

export const selectBusinessContactId = createSelector(
    getBusinessContactId
    , businessContactId => businessContactId
);

export const selectBusinessDistrict = createSelector(
    getBusinessDistrict
    , district => district
);

export const selectBusinessDistrictId = createSelector(
    getBusinessDistrictId
    , districtId => districtId
);

export const selectIsInternalUser = createSelector(
    getIsInternalUser
    , isInternalUser => isInternalUser
);

export const selectIsMultiBusinessUser = createSelector(
    [ getUserBusinesses, getIsInternalUser ]
    , ( businesses, isInternalUser ) => {
        return isInternalUser || businesses?.length > 1;
    }
);

export const selectUserLoggedIn = createSelector(
    getIsLoggedIn
    , isLoggedIn => isLoggedIn
);

export const selectUserLogoutPending = createSelector(
    getLogoutPending
    , isLogoutPending => isLogoutPending
);

export const selectUserSessionError = createSelector(
    getUserSessionError
    , hasUserSessionError => hasUserSessionError
);

export const selectUserEmail = createSelector(
    getUserEmail
    , email => email
);

export const selectUserFirstName = createSelector(
    getUserFirstName
    , firstName => firstName
);

export const selectUserLastName = createSelector(
    getUserLastName
    , lastName => lastName
);

export const selectSelectedBusiness = createSelector(
    getSelectedBusiness
    , selectedBusiness => selectedBusiness
);

export const selectSelectedBusinessName = createSelector(
    getSelectedBusinessName
    , businessName => businessName
);

export const selectSelectedBusinessBiography = createSelector(
    getSelectedBusinessBiography
    , biography => biography
);

export const selectSelectedBusinessUrls = createSelector(
    getSelectedBusinessUrls
    , urls => urls
);

export const selectShowMultiBusinessUserWarning = createSelector(
    getshowMultiBusinessUserWarning, showMultiBusinessUserWarning => showMultiBusinessUserWarning
);

export const selectLocations = createSelector(
    getLocations
    , locations => locations
);

export const selectBusinessPaymentType = createSelector(
    getBusinessPaymentType
    , businessPaymentType => businessPaymentType
);

export const selectIsMarketplaceOn = createSelector(
    getIsMarketplaceOn
    , isMarketplaceOn => isMarketplaceOn
);

export const selectAccessLevel = createSelector(
    getAccessLevel
    , ( accessLevel: number ) => accessLevel
);

export const selectUserTimeZoneId = createSelector(
    getUserTimeZoneId
    , ( timeZoneId: string ) => timeZoneId
);

export const selectSelectedLocation = createSelector(
    getSelectedLocation
    , ( selectedLocation: BusinessLocation ) => selectedLocation
);

export const selectWorkAreas = createSelector(
    getWorkAreas
    , ( workAreas: WorkArea[] ) => workAreas
);

export const selectIsAccounting = createSelector(
    getIsAccounting
    , ( isAccounting: boolean ) => isAccounting
);

export const selectIsSessionCheckPending = createSelector(
    getIsSessionCheckPending
    , ( isSessionCheckPending: boolean ) => isSessionCheckPending
);

export const selectPostedOpsCountAllTime = createSelector(
    getPostedOpsCountAllTime
    , ( postedOpsCount: number ) => postedOpsCount
);

export const selectCreatedAt = createSelector(
    getCreatedAt
    , ( createdAt: string ) => createdAt
);

export const selectOpTemplates = createSelector(
    getOpTemplates
    , ( opTemplates: OpTemplatesState ) => opTemplates
);

export const selectIsLoggedIn = createSelector(
    ( state: UserState ) => state.loggedIn
    , ( isLoggedIn: boolean ) => isLoggedIn
);

export const selectIsLoginPending = createSelector(
    ( state: UserState ) => state.loginPending
    , ( isLoginPending: boolean ) => isLoginPending
);

export const selectLoginErrorMessage = createSelector(
    ( state: UserState ) => state.error
    , ( errorMessage: string ) => errorMessage
);

export const selectDistrict = createSelector(
    ( state: UserState ) => state.district || ''
    , ( district: string ) => district
);

export const selectSuspensions = createSelector(
    getSuspensions
    , ( suspensions: Suspension[] ) => suspensions
);

export const selectWorkAreaNameById = createSelector(
    [
    // Usual first input - extract value from `state`
        ( state: StoreState ) => state.user.selectedBusiness?.workAreas
        // Take the second arg, `category`, and forward to the output selector
        , ( state, id ) => id
    ],
    ( workAreas, id ) =>
        workAreas?.find( ( workArea: WorkArea ) => workArea.id === id )?.name
);

export const selectUserRole = createSelector(
    getUserRole
    , ( userRole: string ) => _.capitalize( userRole )
);

export const selectIsBusinessW2 = createSelector(
    getBusinessW2
    , ( isW2: boolean ) => isW2
);

export const selectBusinessValidationStatus = createSelector(
    getBusinessValidationStatus
    , ( validationStatus: BusinessValidationStatus ) => validationStatus
);

export const selectors = {
    selectIsLoggedIn
    , selectIsLoginPending
    , selectLoginErrorMessage
};

export default selectors;
