// Libraries
import { jwtDecode } from 'jwt-decode';

// Utils
import { makeAPICall } from '@/utils/axiosInstance';

// Types
import { GetAuthTokenOutput } from '../services/types';
import Cookies from 'js-cookie';

export const getAccessTokenDetails = async ( businessId: number ) => {
    const accessToken = await makeAPICall<GetAuthTokenOutput>( {
        method: 'POST'
        , url: 'business-portal-web/workforce-management/auth/token'
        , data: { businessId: businessId }
        , isBFFEndpoint: true
    } );
    const decodedToken = jwtDecode<{'https://hasura.io/jwt/claims': {'x-hasura-location-id': string; 'x-hasura-business-id': string}; exp: string}>( accessToken.accessToken );
    const locationId = decodedToken[ 'https://hasura.io/jwt/claims' ][ 'x-hasura-location-id' ];

    Cookies.set( `wfm.sid.${ locationId }`, accessToken.accessToken, {
        expires: new Date( Number( decodedToken.exp ) * 1000 )
    } );

    return {
        locationId: Number( locationId )
        , businessId: Number( decodedToken[ 'https://hasura.io/jwt/claims' ][ 'x-hasura-business-id' ] )
    };
};
